<template>
    <section>
        <div class="field">
            <label class="label">{{ label }}</label>
            <div class="control is-flex">
                <input type="number"
                       class="input"
                       v-model="inputid"
                       @change="getId"
                       :readonly="leitura"
                       :disabled="leitura"
                       style="width: 24%;" />
                <b-autocomplete :data="data"
                                icon="magnify"
                                ref="autocomplete"
                                :field="nomeCampo"
                                :loading="isFetching"
                                @typing="getAsyncData"
                                @focus="getAsyncData"
                                :open-on-focus="listWhenEmpty"
                                :readonly="leitura"
                                :disabled="leitura"
                                @select="selectItem"
                                :style="autocompleteStyle">
                    <template slot="empty">
                        Nenhum {{ label }} encontrado
                    </template>
                    <template slot-scope="props">
                        <div class=" is-justified-between">
                            <span :title="props.option[nomeCampo]" :class="{'text-overflow': isBuscarExame}">{{ props.option[nomeCampo] }}</span>
                            <span class="tag" style="float: right;">{{ props.option.id }}</span>
                        </div>
                        <div v-if="infoConvenio">
                            <div class="is-flex is-flex-direction-column is-size-7" v-if="campos && campos.length">
                                <span v-for="item in campos" :key="item.campo">
                                    <template v-if="item.nome && item.campo && props.option[item.campo]">
                                        {{ item.nome }}: <b>{{ props.option[item.campo] }}</b>
                                    </template>
                                </span>
                            </div>
                        </div>
                        <div v-else>
                            <div class=" is-justified-between" v-if="campos && campos.length">
                                <span v-for="item in campos" :key="item.campo">{{item.nome}}: <b>{{ props.option[item.campo] }}</b></span>
                            </div>
                        </div>
                        <div v-for="item in campos" :key="item.campo">
                            <span v-if="props.option[item.observacao] != null && config.exibirObservacaoMedicoNaBusca">{{ $t('GUIAATENDIMENTO.OBSERVACOES') }}: {{ props.option[item.observacao] }}</span>
                        </div>
                        <div class="" v-for="item in camposAgendamento" :key="item.campo">
                            <div class="pr-5" v-if="props.option[item.especie] && props.option[item.raca]">
                                <span class="pr-5"> <b>{{ $t('AGENDAMODAL.ESPECIE') }}:</b> {{ props.option[item.especie] }}</span>
                                <span> <b>{{ $t('AGENDAMODAL.RACA') }}:</b> {{ props.option[item.raca] }}</span>
                            </div>
                            <span v-if="props.option[item.responsavel]"> <b>{{ $t('AGENDAMODAL.RESPONSAVEL') }}:</b> {{ props.option[item.responsavel] }}</span>
                        </div>
                    </template>
                </b-autocomplete>
                <slot></slot>
            </div>
        </div>
    </section>
</template>
<style scoped>
    .text-overflow {
        display: inline-block;
        max-width: 20ch; 
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; 
    }
</style>

<script>
    import debounce from 'lodash/debounce'
    import { mapState } from 'vuex'

    export default {
        props: {
            id: Number,
            nome: String,
            label: String,
            table: String,
            trazDesativado: Boolean,  
            trazCarteira: Boolean,    
            infoConvenio: Boolean,
            isBuscarExame: Boolean,
            field: null,
            searchField: null,
            leitura: null,
            campos: null,
            camposAgendamento: null,
            camposAdicionais: {
                type: String,
                default: ''
            },
            preFetch: { // pre fetch content and serch locally.
                default: false,
                type: Boolean,
            },
            listWhenEmpty: { // list results with empty search.
                default: false,
                type: Boolean,
            }
        },
        data() {
            return {
                data: [],
                inputid: this.id,
                isFetching: false,
                nomeCampo: 'nome',
                campoBusca: 'nome',
                preFetchedData: []
            }
        },
        mounted() {
            if(this.preFetch)
                this.preFetchData();

            this.nomeCampo = this.field ? this.field : this.nomeCampo;
            this.campoBusca = this.searchField ? this.searchField : this.campoBusca;
            if (this.id){
                this.inputid = this.id
                this.getId()
            }
        },
        watch: {
            id() {
                this.inputid = this.id
                this.getId()
            },
            field(){
                this.nomeCampo = this.field ? this.field : this.nomeCampo;
            },
            searchField(){
                this.campoBusca = this.searchField ? this.searchField : this.campoBusca;
            }
        },
        computed: {
            hasDefaultSlot() {
                return !!this.$scopedSlots.default
            },
            autocompleteStyle() {
                return "width: " +
                        (this.hasDefaultSlot
                            ? "69"
                            : "75") + "%"
            },          
        
            ...mapState([
                'config',
            ])
        },
        
        methods: {
            getAsyncData: debounce(function (strOrEvent) {
                let name;
                if(strOrEvent instanceof Event)
                    name = strOrEvent.target.value || "";
                else
                    name = strOrEvent;

                if (!name.length) {
                    if(!this.listWhenEmpty)
                    {
                        this.data = []
                        return
                    }
                    name = "_"
                }

                if(this.preFetchedData.length) {
                    this.data = [];
                    if(name == "_")
                        this.preFetchedData.forEach(item => this.data.push(item));
                    else
                        this.data = this.preFetchedData.filter((item) => item[this.campoBusca].toLowerCase().includes(name.toLowerCase()));
                    console.log(this.data);
                    return;
                }

                this.isFetching = true
                this.$http.get(`/Search/${this.table}?${this.campoBusca}=${name}&trazDesativado=${this.trazDesativado}${this.camposAdicionais}`)
                    .then(({ data }) => {
                        this.data = []
                        data.forEach((item) => this.data.push(item))
                    })
                    .catch((error) => {
                        this.data = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false
                    })
            }, 500),
            preFetchData: function() {
                return this.$http.get(`/Search/${this.table}?${this.campoBusca}=_&trazDesativado=${this.trazDesativado}${this.camposAdicionais}`)
                .then(({data}) => {
                    data.forEach((item) => this.preFetchedData.push(item))
                });
            },
            getId() {                
                if(this.inputid == ""){                    
                    this.inputid = null;                    
                    this.$refs.autocomplete.setSelected({
                        [this.nomeCampo]: null,
                        data: null
                    });
                    this.$emit('update:id', this.inputid);      
                    this.$emit('update:nome', null);         
                }
                if(this.inputid && !this.isFetching){
                                       
                    this.isFetching = true
                    const idInputAjax = this.inputid;
                    this.$http.get(`/Search/${this.table}Id?id=${this.inputid}&trazDesativado=${this.trazDesativado}${this.camposAdicionais}`)
                        .then(({ data }) => {
                            if(data.fail){
                                this.$buefy.toast.open({
                                    duration: 3000,
                                    message: this.table + " " + idInputAjax + ' não encontrado.',
                                    type: 'is-danger',
                                    queue: false
                                })
                                this.inputid = null
                                this.$refs.autocomplete.setSelected({
                                    [this.nomeCampo]: null,
                                    data: null
                                })   
                                this.$emit('update:id', this.inputid)  
                                this.$emit('update:nome', null);         
                            }
                            if(data.desativado){
                                this.$buefy.toast.open({
                                    duration: 3000,
                                    message: this.table + " " + idInputAjax + ' desativado.',
                                    type: 'is-danger',
                                    queue: false
                                })
                                this.inputid = null
                                this.$refs.autocomplete.setSelected({
                                    [this.nomeCampo]: null,
                                    data: null
                                })   
                                this.$emit('update:id', this.inputid);
                                this.$emit('update:nome', null);
                            }
                            else {
                                this.$emit('update:id', parseInt(this.inputid))  
                                this.$emit('update:nome', data[this.nomeCampo]);
                                this.$emit('update:inscricaoObrigatoria', data.inscricaoObrigatoria);

                                if(this.trazCarteira){
                                    this.$emit('update:carteiraPadrao', data.carteiraPadrao);                                   
                                }
                                
                                this.$refs.autocomplete.setSelected({
                                    [this.nomeCampo]: data[this.nomeCampo],
                                    data: data
                                })
                            }
                            
                        })
                        .catch((error) => {
                            this.data = []
                            throw error
                        })
                        .finally(() => {
                            this.isFetching = false
                        })
                }
            },            
            selectItem(option) {                
                if (option && option.id){
                    this.inputid = option.id
                    this.$emit('update:id', option.id)
                    this.$emit('update:nome', option.nome);
                }
                this.$emit('item', option);
            }
        }
    }
</script>
